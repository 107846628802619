import React from 'react'
import {BsPhone, BsWhatsapp, BsYoutube} from "react-icons/bs"
import {FaFacebook, FaLinkedin} from "react-icons/fa"

export default function SocialMedia() {
  return (
    <div className='app__social'>
      <div>
        <a href="https://web.facebook.com/profile.php?id=61568880154807" target='_blank'>
        <FaFacebook />
        </a>
      </div>
      <div>
        <a href="https://www.linkedin.com/company/105718232/" target='_blank'>
        <FaLinkedin/>
        </a>
      </div>
      <div>
        <a href="https://www.youtube.com/@KayanXTech" target='_blank'>
        <BsYoutube/>
        </a>
      </div>
      <div>
        <a href="https://api.whatsapp.com/send?phone=01026956499" target='_blank'>
        <BsWhatsapp/>
        </a>
      </div>
    </div>
  )
}
