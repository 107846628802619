export const ouremail = { avatar: "../../public/assets/email.png" };
export const ouremail2 = { avatar: "../assets/mail.png" };
export const mobile = { avatar: "../assets/mobile.png" };
export const api = { avatar: "../assets/api.png" };
export const cpp = { avatar: "../assets/cpp.png" };
export const css = { avatar: "../assets/css.png" };
export const figma = { avatar: "../assets/figma.png" };
export const flutter = { avatar: "../assets/flutter.png" };
export const git = { avatar: "../assets/git.png" };
export const graphql = { avatar: "../assets/graphql.png" };
export const html = { avatar: "../assets/html.png" };
export const javascript = { avatar: "../assets/javascript.png" };
export const mu5 = { avatar: "../assets/mu5.png" };
export const node = { avatar: "../assets/node.png" };
export const python = { avatar: "../assets/python.png" };
export const react = { avatar: "../assets/react.png" };
export const redux = { avatar: "../assets/redux.png" };
export const sass = { avatar: "../assets/sass.png" };
export const typescript = { avatar: "../assets/typescript.png" };
export const angular = { avatar: "../assets/Angular.png" };
export const dotnet = { avatar: "../assets/Microsoft_.NET_logo.svg.png" };
export const dotnetlogo = { avatar: "../assets/dotnet-icon.svg" };
export const charp = { avatar: "../assets/C_Sharp_Icon.png" };
export const azure = { avatar: "../assets/Microsoft_Azure.svg.png" };
export const bootstrap = { avatar: "../assets/Bootstrap.png" };
export const iotdev = { avatar: "../assets/iot.webp" };
export const aidev = { avatar: "../assets/aidev.webp" };
export const desktopdev = { avatar: "../assets/desktopdev.webp" };
export const lms = { avatar: "../assets/lms.webp" };

export const image1 = { avatar: "../assets/image1.png" };
export const image2 = { avatar: "../assets/image2.png" };
export const image3 = { avatar: "../assets/image3.png" };
export const image4 = { avatar: "../assets/image4.png" };
export const image5 = { avatar: "../assets/image5.png" };
export const image6 = { avatar: "../assets/image6.png" };
export const image7 = { avatar: "../assets/image7.png" };
export const image8 = { avatar: "../assets/image8.png" };
export const image9 = { avatar: "../assets/image9.png" };
export const image10 = { avatar: "../assets/image10.png" };


export const about01 = { avatar: "../assets/about01.png" };
export const about02 = { avatar: "../assets/about02.png" };
export const about03 = { avatar: "../assets/about03.png" };
export const about04 = { avatar: "../assets/about04.png" };
// brands
export const brand1 = { avatar: "../assets/brand1.jpeg" };
export const brand2 = { avatar: "../assets/brand2.jpeg" };
export const brand3 = { avatar: "../assets/brand3.jpeg" };
export const brand4 = { avatar: "../assets/brand4.jpeg" };
export const brand5 = { avatar: "../assets/brand5.jpeg" };
export const brand6 = { avatar: "../assets/brand6.jpeg" };
export const brand7 = { avatar: "../assets/brand7.jpeg" };
export const brand8 = { avatar: "../assets/brand8.png" };
export const brand9 = { avatar: "../assets/brand9.jpeg" };
export const brand10 = { avatar: "../assets/brand10.jpeg" };

export const profile = { avatar: "../assets/microsoft-cloud-visual-2022-07-cloud-infrastructure.svg" };
export const circle = { avatar: "../assets/circle.svg" };
export const logo = { avatar: "../assets/logo.png" };


export default function Images() {
  return {
    ouremail,
    mobile,
    api,
    cpp,
    css,
    figma,
    flutter,
    git,
    graphql,
    html,
    javascript,
    mu5,
    node,
    python,
    react,
    redux,
    sass,
    typescript,
    angular,
    about01,
    about02,
    about03,
    about04,
    profile,
    circle,
    logo,
  };
}
