import React, { useRef, useState } from "react";
import "./footer.scss";
import { mobile, ouremail2 } from "../../components/Images";
import emailjs from "@emailjs/browser";

export default function Footer() {
  const [loading, setLoading] = useState(false);
  const form = useRef<any>();

  const sendEmail = (e: any) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .sendForm("service_1seqr0x", "template_ytx9qbi", form.current, "R4DicKgRykFyMgzSc")
      .then(
        (result) => {
          setLoading(false);
          alert("I received your message!");
          form.current.reset();
        },
        (error) => {
          setLoading(false);
          alert("Sorry, there was some error");
          form.current.reset();
        }
      );
  };

  return (
    <>
      <h2 className="head-text mt-5">Chat with us</h2>
      <div className="container contact-form">
        <div className="contact-image">
          <img loading="lazy" src={ouremail2.avatar} alt="rocket_contact" />
        </div>
        <form className="form" ref={form} onSubmit={sendEmail}>
          <h3>Drop Us a Message</h3>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group my-4">
                <input
                required
                  type="text"
                  name="txtName"
                  className="form-control"
                  placeholder="Your Name *"
                />
              </div>
              <div className="form-group my-4">
                <input
                required
                  type="text"
                  name="txtEmail"
                  className="form-control"
                  placeholder="Your Email *"
                />
              </div>
              <div className="form-group my-4">
                <input
                required
                  type="text"
                  name="txtPhone"
                  className="form-control"
                  placeholder="Your Phone Number *"
                />
              </div>
              <div className="form-group my-4">
                {loading ? (
                  <button className="btnContact" value="Send Message">
                    <span
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"></span>
                    Loading...
                  </button>
                ) : (
                  <input
                    type="submit"
                    name="btnSubmit"
                    className="btnContact"
                    value="Send Message"
                  />
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group my-4">
                <textarea
                required
                  name="txtMsg"
                  className="form-control"
                  placeholder="Your Message *"
                  style={{ width: "100%", height: "150px" }}></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
