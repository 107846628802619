import React from 'react'
import "./about.scss"
import {motion} from "framer-motion"
import { aidev, azure, desktopdev, dotnet, figma, flutter, iotdev, lms} from '../../components/Images'

const abouts: any = [
  {title: 'Web Development', description: 'Unleash your digital presence with custom-built websites that do more than just look great', imageUrl: dotnet.avatar},
  {title: 'Mobile Development', description: 'Turn your idea into a seamless mobile experience', imageUrl: flutter.avatar},
  {title: 'Web Design', description: 'Beautiful design isn’t just about aesthetics – it’s about creating experiences', imageUrl: figma.avatar},
  {title: 'Cloud', description: 'Take your business to the cloud for scalability and efficiency', imageUrl: azure.avatar},
  {title: 'IOT', description: 'Bridge the gap between physical and digital with our IoT solutions', imageUrl: iotdev.avatar},
  {title: 'AI', description: 'Transform your data into actionable insights and automated solutions', imageUrl: aidev.avatar},
  {title: 'Desktop', description: 'Need a powerful desktop solution? We’ve got you covered', imageUrl: desktopdev.avatar},
  {title: 'Traninig', description: 'Providing training and courses with all various technology ', imageUrl: lms.avatar},
]

export default function About() {
  
  return (
    <>
    <h2 className='head-text'>
      We Know that <span>Good Design</span>
      <br />
      mens <span>Good Business</span>
    </h2>

    <div className='app__profiles'>
      {abouts.map((item:any, index:any) => (
        <motion.div
        whileInView={{opacity: 1}}
        whileHover={{scale: 1.1}}
        transition={{duration: 0.5, type: 'tween'}}
        className='app__profile-item'
        key={item.title + index}
        >
          <img className='service-img' loading='lazy' src={item.imageUrl} alt={item.title} />
          <h2 className='bold-text' style={{marginTop:'20px'}}>{item.title}</h2>
          <p className='p-text' style={{marginTop:'10px'}}>{item.description}</p>
        </motion.div>
      ))}
    </div>
    </>
  )
}
