import React from 'react'
import "./header.scss"
import { motion } from 'framer-motion';
import {  dotnetlogo, flutter, profile, react } from '../../components/Images';
import SocialMedia from '../../components/SocialMedia';

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
};

export default function Header() {
  return (
    <div id='#home' className="app__header app__flex">
      <SocialMedia/>
    <motion.div
      whileInView={{ x: [-100, 0], opacity: [0, 1] }}
      transition={{ duration: 0.5 }}
      className="app__header-info"
    >
      <div className="app__header-badge">
        <div className="badge-cmp app__flex">
          <span>👋</span>
          <div style={{ marginLeft: 20 }}>
            <p className="p-text">Welcom to</p>
            <h1 className="head-text">KayanX</h1>
          </div>
        </div>

        <div className="tag-cmp app__flex">
          <p className="p-text text-capitalize">Web Development</p>
          <p className="p-text text-capitalize">Mobile Development</p>
          <p className="p-text text-capitalize">Cloud Sloution</p>
        </div>
      </div>
    </motion.div>

    <motion.div
      whileInView={{ opacity: [0, 1] }}
      transition={{ duration: 0.5, delayChildren: 0.5 }}
      className="app__header-img"
    >
      <img loading='lazy' src={profile.avatar} alt="profile_bg" />
    </motion.div>

    <motion.div
      variants={scaleVariants}
      whileInView={scaleVariants.whileInView}
      className="app__header-circles"
    >
      {[flutter.avatar, dotnetlogo.avatar, react.avatar].map((circle, index) => (
        <div className="circle-cmp app__flex" key={`circle-${index}`}>
          <img loading='lazy' src={circle} alt="profile_bg" />
        </div>
      ))}
    </motion.div>
  </div>
  )
}



