import {
  angular,
  api,
  azure,
  bootstrap,
  charp,
  css,
  dotnet,
  figma,
  flutter,
  git,
  graphql,
  image1,
  image2,
  image3,
  image4,
  image5,
  image10,
  image7,
  image8,
  javascript,
  mobile,
  node,
  python,
  react,
  redux,
  sass,
  typescript,
  image9,
  image6,
} from "../../components/Images";

export const allDataOfWork: any = [
  {
    id: 1,
    title: "Web Development",
    category: "Web App",
    imgUrl: image9.avatar,
    codeLink: "",
  },
  {
    id: 1,
    title: "Web Development",
    category: "Web App",
    imgUrl: image6.avatar,
    codeLink: "",
  },
  {
    id: 2,
    title: "Mobile Development",
    category: "Mobile",
    imgUrl: image8.avatar,
    codeLink: "",
  },
  {
    id: 3,
    title: "Web Design",
    category: "UI/UX",
    imgUrl: image3.avatar,
    codeLink: "",
  },
  {
    id: 4,
    title: "Cloud",
    category: "Web App",
    imgUrl: image2.avatar,
    codeLink: "",
  },
  {
    id: 5,
    title: "IOT",
    category: "Web App",
    imgUrl: image7.avatar,
    codeLink: "",
  },
  {
    id: 6,
    title: "AI",
    category: "All",
    imgUrl: image4.avatar,
    codeLink: "",
  },
  {
    id: 7,
    title: "Desktop",
    category: "All",
    imgUrl: image10.avatar,
    codeLink: "",
  },
  {
    id: 8,
    title: "Traninig",
    category: "All",
    imgUrl: image5.avatar,
    codeLink: "",
  },
];

export const SkillsData = [
  {
    id: 1,
    name: "React",
    bgColor: "",
    imgUrl: react.avatar,
  },
  {
    id: 2,
    name: "Redux",
    bgColor: "",
    imgUrl: redux.avatar,
  },
  {
    id: 3,
    name: "JavaScript",
    bgColor: "",
    imgUrl: javascript.avatar,
  },
  {
    id: 4,
    name: "TypeScript",
    bgColor: "",
    imgUrl: typescript.avatar,
  },
  {
    id: 5,
    name: "Angular",
    bgColor: "",
    imgUrl: angular.avatar,
  },
  {
    id: 6,
    name: "Sass",
    bgColor: "",
    imgUrl: sass.avatar,
  },
  {
    id: 6,
    name: "Css",
    bgColor: "",
    imgUrl: css.avatar,
  },
  {
    id: 7,
    name: "Figma",
    bgColor: "",
    imgUrl: figma.avatar,
  },
  {
    id: 8,
    name: "Bootstrap",
    bgColor: "",
    imgUrl: bootstrap.avatar,
  },
  {
    id: 9,
    name: "Mobile",
    bgColor: "",
    imgUrl: mobile.avatar,
  },
  {
    id: 10,
    name: "API",
    bgColor: "",
    imgUrl: api.avatar,
  },
  {
    id: 11,
    name: "Graphql",
    bgColor: "",
    imgUrl: graphql.avatar,
  },
  {
    id: 12,
    name: "Flutter",
    bgColor: "",
    imgUrl: flutter.avatar,
  },
  {
    id: 13,
    name: "Node",
    bgColor: "",
    imgUrl: node.avatar,
  },
  {
    id: 14,
    name: "Git",
    bgColor: "",
    imgUrl: git.avatar,
  },
  {
    id: 15,
    name: "Python",
    bgColor: "",
    imgUrl: python.avatar,
  },
  {
    id: 16,
    name: "ASP.NET Core",
    bgColor: "",
    imgUrl: dotnet.avatar,
  },
  {
    id: 17,
    name: "C#",
    bgColor: "",
    imgUrl: charp.avatar,
  },
  {
    id: 18,
    name: "Cloud Service",
    bgColor: "",
    imgUrl: azure.avatar,
  },
];
