import React from 'react'
import Work from './container/Work/Work'
import Header from './container/Header/Header'
import About from './container/About/About'
import Skills from './container/Skills/Skills'
import Footer from './container/Footer/Footer'
import NavBar from './components/NavBar'
import "./app.scss"

import Brands from './container/Brands/Brands'

export default function App() {
  return (
    <div className='app'>
      <NavBar/>
      <Header/>
      <About/>
      <Work/>
      <Skills/>
      <Brands/>
      <Footer/>
    </div>
  )
}
