import React from "react";
import "./brand.scss";
import { brand1, brand10, brand2, brand3,
  brand4, brand5, brand6, brand7, brand8, brand9 } from "../../components/Images";


export default function Brands() {
  return (
    <>
      <h2 className="head-text my-4">
        Our <span>Clients</span>
      </h2>
      <section className="brand_area section_gap_bottom">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="single-brand-item d-table">
                    <div className="d-table-cell text-center">
                      <img loading='lazy' src={brand1.avatar} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="single-brand-item d-table">
                    <div className="d-table-cell text-center">
                      <img loading='lazy' src={brand2.avatar} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="single-brand-item d-table">
                    <div className="d-table-cell text-center">
                      <img loading='lazy' src={brand3.avatar} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="single-brand-item d-table">
                    <div className="d-table-cell text-center">
                      <img loading='lazy' src={brand4.avatar} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="single-brand-item d-table">
                    <div className="d-table-cell text-center">
                      <img loading='lazy' src={brand5.avatar} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="single-brand-item d-table">
                    <div className="d-table-cell text-center">
                      <img loading='lazy' src={brand6.avatar} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="single-brand-item d-table">
                    <div className="d-table-cell text-center">
                      <img loading='lazy' src={brand7.avatar} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="single-brand-item d-table">
                    <div className="d-table-cell text-center">
                      <img loading='lazy' src={brand8.avatar} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="single-brand-item d-table">
                    <div className="d-table-cell text-center">
                      <img loading='lazy' src={brand9.avatar} alt="" />
                    </div>
                  </div>
                </div>
								<div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="single-brand-item d-table">
                    <div className="d-table-cell text-center">
                      <img loading='lazy' src={brand10.avatar} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="client-info">
                <div className="d-flex mb-50">
                  <span className="lage">10</span>
									<br />
                  <span className="smll">Years Experience Working</span>
                </div>
                <div className="call-now d-flex">
                  <div>
                    <a href="https://api.whatsapp.com/send?phone=01026956499"
                    target='_blank'
                    className="text-decoration-none text-black"
                    >
                    <span className="bi bi-telephone"></span>
                    </a>
                  </div>
                  <div className="ml-15">
                    <p className="text-capitalize">call us now from egypt</p>
                    <h3>(+20)-1026956499</h3>
                  </div>
                </div>
                <div className="call-now d-flex">
                  <div>
                  <a href="https://api.whatsapp.com/send?phone=+968 97151882" 
                  target='_blank'
                    className="text-decoration-none text-black">
                    <span className="bi bi-telephone"></span>
                  </a>
                  </div>
                  <div className="ml-15 ">
                    <p className="text-capitalize">call us now from Oman</p>
                    <h3>+968 97151882</h3>
                  </div>
                </div>
                <div className="call-now d-flex">
                  <div>
                    <a href="https://api.whatsapp.com/send?phone=+971 55 157 1395"
                    target='_blank'
                    className="text-decoration-none text-black"
                    >
                    <span className="bi bi-telephone"></span>
                    </a>
                  </div>
                  <div className="ml-15">
                    <p className="text-capitalize">call us now from The UAE</p>
                    <h3>+971 55 157 1395</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}



// https://api.whatsapp.com/send?phone