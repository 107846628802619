import React, { useState } from "react";
import "./work.scss";
import { motion } from "framer-motion";
import { allDataOfWork } from "./AllData";
import { IoImageOutline } from "react-icons/io5";



export default function Work() {
  const [activeFilter, setActiveFilter] = useState('All');
  const [works, setWorks] = useState(allDataOfWork);
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });

  const handelWorkFilter = (item:any) => {
    setActiveFilter(item);

    setAnimateCard({ y: 100, opacity: 0 });

    setTimeout(() => {
      setAnimateCard({ y: 0, opacity: 1 });

      if (item == 'All') {
        setWorks(allDataOfWork);
      } else {
        const filteredWork = allDataOfWork.filter((workImag: any) => 
          workImag.category.toLowerCase() === item.toLowerCase()
        );
        setWorks(filteredWork);
      }
    }, 500);
  };

  return (
    <>
      <h2 className="head-text">
        Our Creative <span>Portfolio</span> Section
      </h2>

      <div className="app__work-filter app__flex">
        {['All', 'Web App', 'Mobile App', 'UI/UX'].map((item, index) => (
          <div key={index}
          onClick={() => handelWorkFilter(item)}
          className={`app__work-filter-item app__flex p-text ${activeFilter === item ? 'item-active' : ''}`}
          >
            {item}
          </div>
        ))}
      </div>

      <motion.div
      animate={animateCard}
      transition={{duration: 0.5, delayChildren: 0.5}}
      className="app__work-portfolio"
      >
        {works.map((work:any, index:any) => (
          <div className="app__work-item app__flex" key={index}>
            <div
              className="app__work-img app__flex"
            >
              <img loading='lazy' src={work.imgUrl} alt={work.title} />

              <motion.div
                whileHover={{ opacity: [0, 1] }}
                transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                className="app__work-hover app__flex"
              >
                <a href={work.projectLink} target="_blank" rel="noreferrer">

                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.90] }}
                    transition={{ duration: 0.25 }}
                    className="app__flex"
                  >
                    <IoImageOutline />
                  </motion.div>
                </a>
              </motion.div>
            </div>

            <div className="app__work-content app__flex">
              <h4 className="bold-text">{work.title}</h4>
              {/* <p className="p-text" style={{ marginTop: 10 }}>{work.description}</p> */}

              <div className="app__work-tag app__flex">
                <p className="p-text">{work.title}</p>
              </div>
            </div>
          </div>
        ))}
      </motion.div>

    </>
  );
}
