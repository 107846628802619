import React from "react";
import "./skills.scss";
import { motion } from "framer-motion";
import { SkillsData } from "../Work/AllData";

export default function Skills() {
  return (
    <>
      <h2 className="head-text">Our Technology Skills & Experiences</h2>
      
      <div className="app__skills-container ">
      <motion.div className="app__skills-list app__flex">
          {SkillsData.map((skill) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
              key={skill.name}
            >
              <div
                className="app__flex"
                style={{ backgroundColor: skill.bgColor }}
              >
                <img loading='lazy' src={skill.imgUrl} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
            </motion.div>
          ))}
        </motion.div>

      </div>
    </>
  );
}
